<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 20.9707H14V10.9707H10V20.9707ZM4 20.9707H8V15.9707H4V20.9707ZM16 14.0957V20.9707H20V14.0957H16Z"
      fill="currentColor"
    />
    <path
      d="M11.5214 3.5506C11.6647 3.07696 12.3353 3.07696 12.4786 3.5506L12.8605 4.81328C12.9244 5.02422 13.1187 5.16851 13.3391 5.16851H14.4406C14.9269 5.16851 15.127 5.79248 14.7313 6.07528L13.8253 6.72289C13.6501 6.84807 13.5748 7.07127 13.6382 7.27698L14.0095 8.48146C14.1527 8.94592 13.6161 9.32095 13.2292 9.02683L12.3023 8.32232C12.1236 8.18648 11.8763 8.18638 11.6975 8.32208L10.7685 9.02711C10.3812 9.32099 9.8448 8.94532 9.98858 8.48094L10.3612 7.27729C10.425 7.07145 10.3497 6.84793 10.1744 6.72263L9.26867 6.07528C8.873 5.79248 9.07307 5.16851 9.55941 5.16851H10.6609C10.8813 5.16851 11.0756 5.02422 11.1395 4.81328L11.5214 3.5506Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcRank',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>

<style scoped lang="scss"></style>
